import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '../common/button/Button.component'

const useStyles = makeStyles(() => ({
  button: {
    maxWidth: '38.5rem',
    width: '100%',
    display: 'block',
    margin: '0 auto 2.5rem auto',
  },
  buttonContainer: {
    padding: '0 1rem',
  },
}))

const ButtonQuotationSend = ({ onClick }) => {
  const classes = useStyles()

  return (
    <div className={classes.buttonContainer}>
      <Button
        id='btn-enviar-cotizador-autos'
        primary
        onClick={onClick}
        classNameProps={classes.button}
      >
        <span>Enviar</span>
      </Button>
    </div>
  )
}

export default ButtonQuotationSend
